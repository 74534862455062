<template>
  <div style="margin: 20px">
    <el-divider content-position="left"
      ><h1 style="font-size: 500; font-size: 16px">{{ projectName }}</h1></el-divider
    >
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="渣土场配置" name="first">
        <el-row>
          <el-button size="small" type="primary" icon="el-icon-circle-plus" @click="addMuckSite">添加渣土场</el-button>
        </el-row>
        <vxe-table border :data="ztcList">
          <vxe-table-column align="center" type="seq" width="80" title="序号"></vxe-table-column>
          <vxe-table-column align="center" field="name" title="渣土场名称"></vxe-table-column>
          <vxe-table-column align="center" title="操作">
            <template slot-scope="scope">
              <span style="color: #409eff; cursor: pointer" @click="ztcDelHandle(scope.row)">删除</span>
            </template>
          </vxe-table-column>
        </vxe-table>
      </el-tab-pane>
      <el-tab-pane label="土地类型配置" name="second">
        <el-row>
          <el-button size="small" type="primary" icon="el-icon-circle-plus" @click="addLandType">添加土地类型</el-button>
        </el-row>
        <vxe-table border :data="tlxList">
          <vxe-table-column align="center" type="seq" width="80" title="序号"></vxe-table-column>
          <vxe-table-column align="center" field="name" title="土地类型名称"></vxe-table-column>
          <vxe-table-column align="center" title="操作">
            <template slot-scope="scope">
              <span style="color: #409eff; cursor: pointer" @click="tlxDelHandle(scope.row)">删除</span>
            </template>
          </vxe-table-column>
        </vxe-table></el-tab-pane
      >
      <el-tab-pane label="放行规则配置" name="third">
        <el-row v-if="passConfigDetail !== null">
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title">车队名称必选：</span>
                <span class="config-item_switch">
                  <el-switch
                    @click="v => savePassConfig('fleetIsRequired', '车队名称必选', v)"
                    active-value="true"
                    inactive-value="false"
                    v-model="passConfigDetail.fleetIsRequired"
                  />
                </span>
              </div>
              <span class="config-item_remark">注：开启后出场放行车队名称必选（默认开）</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title" style="width: 200px"> 进出场扫描配置：</span>
                <span style="width: 100%">
                  <el-radio v-model="passConfigDetail.scanConfigCount" label="1">进场扫描一次</el-radio>
                  <el-radio v-model="passConfigDetail.scanConfigCount" label="2">进场扫描两次</el-radio>
                </span>
              </div>
              <span class="config-item_remark">(默认：进场出场扫描一次) </span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title">打印显示价格：</span>
                <span class="config-item_switch">
                  <el-switch
                    @change="v => savePassConfig('printWithPrice', '打印显示价格：', v)"
                    active-value="true"
                    inactive-value="false"
                    v-model="passConfigDetail.printWithPrice"
                  />
                </span>
              </div>
              <span class="config-item_remark">注：开启后打印工单将显示价格（默认关）</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title" style="width: 210px"> 异常放行时间配置：</span>
                <span style="width: 100%">
                  <el-input v-model="passConfigDetail.outExceptionTimeConfig" style="width: 40%" size="small">
                    <template slot="append">分钟</template>
                  </el-input>
                </span>
              </div>
              <span class="config-item_remark">注：同一车牌在该自定义时间范围内重复放行会提醒异常，只支持正整数，范围5-30（默认30分钟） </span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title">出场放行显示选择打印联数：</span>
                <span class="config-item_switch">
                  <el-switch
                    @change="v => savePassConfig('outPrintCount', '出场放行显示选择打印联数', v)"
                    active-value="true"
                    inactive-value="false"
                    v-model="passConfigDetail.outPrintCount"
                  />
                </span>
              </div>
              <span class="config-item_remark">注：出场放行显示选中打印工单联数（默认开）</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title" style="width: 200px"> 出场放行打印联数：</span>
                <span style="width: 100%">
                  <el-radio v-model="passConfigDetail.outAccessPringCount" label="1">一联</el-radio>
                  <el-radio v-model="passConfigDetail.outAccessPringCount" label="2">二联</el-radio>
                  <el-radio v-model="passConfigDetail.outAccessPringCount" label="3">三联</el-radio>
                  <el-radio v-model="passConfigDetail.outAccessPringCount" label="0">不打印</el-radio>
                </span>
              </div>
              <span class="config-item_remark">默认两联 </span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title">管理员进入工地上班前选择上班班次：</span>
                <span class="config-item_switch">
                  <el-switch
                    @change="v => savePassConfig('workTypeIsRequired', '管理员进入工地上班前选择上班班次', v)"
                    active-value="true"
                    inactive-value="false"
                    v-model="passConfigDetail.workTypeIsRequired"
                  />
                </span>
              </div>
              <span class="config-item_remark">注：开启后管理员上班前强制选择上班班次（默认开）</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title">开启车斗照必填：</span>
                <span class="config-item_switch">
                  <el-switch
                    @change="v => savePassConfig('bodyPic', '开启车斗照必填', v)"
                    active-value="true"
                    inactive-value="false"
                    v-model="passConfigDetail.bodyPic"
                  />
                </span>
              </div>
              <span class="config-item_remark">注：开启车斗照必填（默认关）</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title">管理员不在工地2公里范围，无法上班：</span>
                <span class="config-item_switch">
                  <el-switch
                    @change="v => savePassConfig('inTwoOnWork', '管理员不在工地2公里范围，无法上班', v)"
                    active-value="true"
                    inactive-value="false"
                    v-model="passConfigDetail.inTwoOnWork"
                  />
                </span>
              </div>
              <span class="config-item_remark">注：开启后管理员不在工地2公里范围内将无法上班（默认关)</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <div class="config-item">
              <div style="display: flex">
                <span class="config-item_title">管理员删除工单需老板审批：</span>
                <span class="config-item_switch">
                  <el-switch
                    @change="v => savePassConfig('deleteOrderRequireCheck', '管理员删除工单需老板审批', v)"
                    active-value="true"
                    inactive-value="false"
                    v-model="passConfigDetail.deleteOrderRequireCheck"
                  />
                </span>
              </div>
              <span class="config-item_remark">注：开启后管理员删除工单需要老板审批（默认关）</span>
            </div>
          </el-col>
        </el-row></el-tab-pane
      >
    </el-tabs>
    <el-dialog title="添加渣土场" :visible.sync="muckSiteVisble" width="45%">
      <el-form :model="ztcForm" ref="ztcForm" :rules="ztcRules">
        <el-form-item label="渣土场名称:" prop="name">
          <el-input v-model="ztcForm.name" size="small" style="width: 70%" maxlength="20" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="muckSiteVisble = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitMuckSite">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="添加土地类型" :visible.sync="landTypeVisible" width="45%">
      <el-form :model="tlxForm" ref="tlxForm" :rules="tlxRules">
        <el-form-item label="土地类型名称:" prop="name">
          <el-input size="small" v-model="tlxForm.name" style="width: 70%" maxlength="20" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="landTypeVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitLandType">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ztcSave, ztcQuery, ztcDelete, tlxSave, tlxQuery, tlxDelete, passConfig, passConfigEdit } from '@/api/config'
export default {
  data() {
    return {
      activeName: 'first',
      projectName: '',
      radio: '1',
      tlxList: [],
      ztcList: [],
      ztcForm: {
        projectId: '',
        name: ''
      },
      tlxForm: {
        projectId: '',
        name: ''
      },
      ztcRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      tlxRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      queryParams: { projectId: '' },
      muckSiteVisble: false,
      landTypeVisible: false,
      passConfigDetail: {
        outExceptionTimeConfig: '30',
        inTwoOnWork: 'true',
        deleteOrderRequireCheck: 'true',
        fleetIsRequired: 'true',
        workTypeIsRequired: 'true',
        outAccessPringCount: '1',
        printWithPrice: 'true',
        scanConfigCount: '1',
        outPrintCount: 'true',
        bodyPic:'false'
      }
    }
  },
  activated() {
    let projectId = this.$route.query.id
    this.projectName = this.$route.query.name
    this.queryParams.projectId = projectId
    this.tlxForm.projectId = projectId
    this.queryParams.projectId = projectId
    this.tlxForm.projectId = projectId
    this.ztcForm.projectId = projectId
    this.ztcQuery()
    this.tlxQuery()
    this.passConfig()
  },
  created() {},
  watch: {
    'passConfigDetail.outAccessPringCount': {
      handler(v, oldV) {
        this.savePassConfig('outAccessPringCount', '出场放行打印联数', v)
      },
      immediate: true,
      deep: true
    },
    'passConfigDetail.outExceptionTimeConfig': {
      handler(v, oldV) {
        this.savePassConfig('outExceptionTimeConfig', '异常放行时间配置', v)
      },
      immediate: true,
      deep: true
    },
    'passConfigDetail.scanConfigCount': {
      handler(v, oldV) {
        this.savePassConfig('scanConfigCount', '进出场扫描配置', v)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    savePassConfig(key, text, value) {
      let params = {
        key: key,
        value: this.passConfigDetail[`${key}`],
        projectId: this.queryParams.projectId
      }
      if (params.projectId && params.key) {
        passConfigEdit(params).then(res => {
          console.log(res.msg)
          // if (res.code === 200) {
          //   this.$message.success(`${text}】变更成功！`)
          // } else {
          //   this.$message.error(`${text}】变更失败！`)
          // }
        })
      }
    },
    passConfig() {
      console.log("this.queryParams.projectId", this.queryParams.projectId)
      passConfig({ projectId: this.queryParams.projectId }).then(res => {
        console.log("res.code", res.code)
        console.log("res.data", res.data)
        this.passConfigDetail = JSON.parse(res.data.content)
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    // 渣土场查询
    ztcQuery() {
      ztcQuery(this.queryParams).then(res => {
        console.log(res.data)
        this.ztcList = res.data
      })
    },

    //土类型查询
    tlxQuery() {
      tlxQuery(this.queryParams).then(res => {
        this.tlxList = res.data
      })
    },
    // 渣土场删除
    ztcDelHandle(row) {
      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          ztcDelete({ id: row.id }).then(res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.ztcQuery()
            } else {
              this.$message({
                type: 'error',
                message: '删除失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 土类型删除
    tlxDelHandle(row) {
      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          tlxDelete({ id: row.id }).then(res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.tlxQuery()
            } else {
              this.$message({
                type: 'error',
                message: '删除失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    addMuckSite() {
      this.muckSiteVisble = true
    },
    addLandType() {
      this.landTypeVisible = true
    },
    submitMuckSite() {
      this.$refs.ztcForm.validate(valid => {
        if (valid) {
          ztcSave(this.ztcForm).then(res => {
            this.$message.success('添加渣土场成功')
            this.ztcQuery()
            this.muckSiteVisble = false
          })
        }
      })
    },
    submitLandType() {
      this.$refs.tlxForm.validate(valid => {
        if (valid) {
          tlxSave(this.tlxForm).then(res => {
            this.$message.success('添加渣土场成功')
            this.tlxQuery()
            this.landTypeVisible = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.config-item {
  line-height: 35px;
  margin-left: 10px;
  &_title {
    font-weight: 600;
    width: 50%;
  }
  &_switch {
    width: 50%;
  }
  &_remark {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #7f7f7f;
  }
}
</style>
